import React, { useEffect, useRef } from 'react';
import { BrowserMultiFormatReader } from '@zxing/library';
import './style.css';

const ZxingScanner = ({ sendCode, closeCamera }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    startScanner();
  }, []);

  const startScanner = async () => {
    try {
      var codeReader = new BrowserMultiFormatReader();
      await codeReader.listVideoInputDevices();
      codeReader.decodeFromVideoDevice(
        null,
        videoRef.current,
        (result, error) => {
          if (result) {
            sendCode(result.text);

            codeReader.reset();
            closeCamera(false);
          }
        }
      );
    } catch (error) {
      console.error('Barcode scanner error:', error);
    }
  };

  return (
    <div className="content-video">
      <video ref={videoRef} />
    </div>
  );
};

export default ZxingScanner;
