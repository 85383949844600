import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container } from 'react-bootstrap';
import api from '../../../services/API';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import sortFunc from '../../../utils/ordernarColunaInt';
import Toggle from 'react-bootstrap-toggle';
import { toast } from 'react-toastify';
import ModalExcluir from './ModalExcluir/index';
import ModalEditar from './ModalEditar/index';
import { useSelector } from 'react-redux';

const { SearchBar } = Search;

const defaultSorted = [
  {
    dataField: 'valorPontos',
    order: 'desc',
  },
];

export default function ProdutosCriteria() {
  const usuario = useSelector((state) => {
    return state.usuario;
  });

  useEffect(() => {
    buscarProdutos();
  }, []);

  const buscarProdutos = async () => {
    setBuscando(true);
    const resultado = await api.get('/api/produtos?ativo=1');
    if (resultado) {
      setData(resultado.data.produtos);
    }
    setBuscando(false);
  };

  const [data, setData] = useState([]);
  const [buscando, setBuscando] = useState(false);
  const [dataEditar, setDataEditar] = useState(null);
  const [id, setId] = useState('');
  const [produto, setProduto] = useState('');
  const [mostrarModalExcluir, setMostrarModalExcluir] = useState(false);
  const [mostrarModalEditar, setMostrarModalEditar] = useState(false);

  const columns = [
    {
      dataField: 'codigo',
      text: 'Código',
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'nome',
      text: 'Produto',
      sort: true,
    },
    {
      dataField: 'valorUnitario',
      text: 'Valor Unitário',
      formatter: (celula, valor) => {
        return Number(valor.valorUnitario)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      hidden: true,
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'valorPontos',
      text: 'Pontos',
      formatter: (celula, valor) => {
        return Number(valor.valorPontos).toLocaleString('pt-BR');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      isDummyField: true,
      dataField: 'ativo',
      text: 'Ativo',
      sort: true,
      hidden: [2].includes(usuario.permissao) ? false : true,
      formatter: (celula, valor) => {
        return (
          <Toggle
            onClick={(state) => updateStatus(state, valor.id)}
            on={<span>Sim</span>}
            off={<span>Não</span>}
            size="sm"
            offstyle="danger"
            active={valor.ativo === 1 ? true : false}
          />
        );
      },
    },
    {
      dataField: 'editar',
      isDummyField: true,
      text: 'Editar',
      sort: false,
      hidden: [1, 2].includes(usuario.permissao) ? false : true,
      formatter: (celula, valor) => (
        <button
          className="btn-limpo f-12 p-1"
          onClick={() => {
            abrirModalEditar(valor.id);
          }}
        >
          <i className="fas fa-edit"></i>
        </button>
      ),
    },
    {
      dataField: 'apagar',
      isDummyField: true,
      text: 'Apagar',
      sort: false,
      hidden: [2].includes(usuario.permissao) ? false : true,
      formatter: (celula, valor) => (
        <button
          className="btn-limpo f-12 p-1"
          onClick={() => {
            abrirModalExcluir(valor.id, valor.nome);
          }}
        >
          <i className="fas fa-trash f-red"></i>
        </button>
      ),
    },
  ];

  async function updateStatus(state, id) {
    const resultado = await api.put(`/api/produtos/${id}`, {
      ativo: state ? 1 : 0,
    });

    if (resultado) {
      toast.success('Produto atualizado com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });
    }

    setData(
      data.map((d) => {
        if (d.id === id) {
          d.ativo = state ? 1 : 0;
        }
        return d;
      })
    );
  }

  const abrirModalExcluir = (id, produto) => {
    setProduto(produto);
    setId(id);
    setMostrarModalExcluir(true);
  };

  const fecharModalExcluir = () => {
    setProduto('');
    setId('');
    setMostrarModalExcluir(false);
  };

  const abrirModalEditar = (id) => {
    const dados = data.filter((d) => d.id === id);
    setDataEditar(dados[0]);
    setMostrarModalEditar(true);
  };

  const fecharModalEditar = () => {
    setDataEditar([]);
    setMostrarModalEditar(false);
  };

  const atualizarExclusao = (id) => {
    setData(data.filter((d) => d.id !== id));
  };

  const atualizarProduto = (dataUpdated) => {
    setData(
      data.map((d) => {
        if (d.id === dataUpdated.id) {
          d = { ...d, ...dataUpdated };
        }
        return d;
      })
    );
  };

  useEffect(() => {
    if (buscando) {
      setData([]);
    }
  }, [buscando]);

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: data.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Produtos Ruby Rose</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <div>
        <ToolkitProvider
          bootstrap4
          keyField="codigo"
          data={data}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="d-flex flex-row-reverse">
                <SearchBar
                  {...props.searchProps}
                  placeholder="Buscar"
                  className="input-theme mr-2"
                />
              </div>
              <BootstrapTable
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                keyField="codigo"
                pagination={paginationFactory(options)}
                defaultSorted={defaultSorted}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
      <ModalExcluir
        show={mostrarModalExcluir}
        onHide={fecharModalExcluir}
        produto={produto}
        id={id}
        atualizarExclusao={atualizarExclusao}
      />
      <ModalEditar
        show={mostrarModalEditar}
        onHide={fecharModalEditar}
        data={dataEditar}
        atualizarProduto={atualizarProduto}
      />
    </Container>
  );
}
