import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../../services/API';
import historico from '../../../services/Historico';
import SelectRevenda from '../SelectRevenda';

export default function CadastrarProdutoRevenda() {
  const [cnpjRevenda, setCnpjRevenda] = useState('');
  const [codigoPadrao, setCodigoPadrao] = useState('');
  const [nomePadrao, setNomePadrao] = useState('');
  const [codigo, setCodigo] = useState('');
  const [nome, setNome] = useState('');
  const [valorUnitario, setValorUnitario] = useState('');
  const [enviando, setEnviando] = useState(false);

  const cadastrarProdutoRevenda = async (e) => {
    e.preventDefault();
    if (!cnpjRevenda) {
      toast.error('Loja obrigatório', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
    if (!codigoPadrao) {
      toast.error('Código Ruby Rose obrigatório', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
    if (!codigo) {
      toast.error('Código Loja obrigatório', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
    if (!nome) {
      toast.error('Descrição Loja obrigatório', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
    if (!valorUnitario) {
      toast.error('Preço Unitário Loja obrigatório', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    setEnviando(true);
    const resultado = await api.post('/api/produtos_revenda', {
      cnpjRevenda: cnpjRevenda.value,
      codigoPadrao,
      codigo,
      nome,
      valorUnitario,
    });

    if (resultado) {
      toast.success('Produto cadastrado com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      historico.push('/painel/cadastrar/produto/revenda');
    }
    setEnviando(false);
  };

  const buscarCodigoPadrao = async (element) => {
    let codigo = element.target.value;
    if (!codigo) return;
    const resultado = await api.get(`/api/produtos?codigo=${codigo}`);
    if (resultado.data.produtos.length === 0) {
      toast.error('Produto Ruby Rose não encontrado', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setCodigoPadrao('');
      setNomePadrao('');
    } else {
      setNomePadrao(resultado.data.produtos[0].nome);
    }
  };

  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black">Cadastrar Produto Loja</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <form className="form" onSubmit={cadastrarProdutoRevenda}>
          <label htmlFor="cnpjRevenda">Loja</label>
          <SelectRevenda onChange={setCnpjRevenda} />
          <label htmlFor="codigoPadrao">Código Ruby Rose</label>
          <input
            id="codigoPadrao"
            name="codigoPadrao"
            type="text"
            placeholder="Código Ruby Rose"
            onChange={(e) => setCodigoPadrao(e.target.value)}
            value={codigoPadrao}
            onBlur={buscarCodigoPadrao}
          ></input>
          <label htmlFor="revenda">Descrição Ruby Rose</label>
          <input
            id="nomePadrao"
            name="nomePadrao"
            type="text"
            placeholder="Descrição Ruby Rose"
            disabled
            value={nomePadrao}
          ></input>
          <label htmlFor="codigo">Código Loja</label>
          <input
            id="codigo"
            name="codigo"
            type="text"
            placeholder="Código Loja"
            onChange={(e) => setCodigo(e.target.value)}
            value={codigo}
          ></input>
          <label htmlFor="nome">Descrição Loja</label>
          <input
            id="nome"
            name="nome"
            type="text"
            placeholder="Descrição Loja"
            onChange={(e) => setNome(e.target.value)}
            value={nome}
          ></input>
          <label htmlFor="valorUnitario">Preço Unitário Loja</label>
          <input
            id="valorUnitario"
            name="valorUnitario"
            type="number"
            step="0.01"
            placeholder="Preço Unitário Loja"
            onChange={(e) => setValorUnitario(e.target.value)}
            value={valorUnitario}
          ></input>
          {enviando ? (
            <button disabled className="btn-primario" type="submit">
              CADASTRANDO...
            </button>
          ) : (
            <button className="btn-primario" type="submit">
              CADASTRAR
            </button>
          )}
          <button
            className="btn-secundario"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              historico.push('/painel/cadastrar/produto');
            }}
          >
            VOLTAR
          </button>
        </form>
      </div>
    </Container>
  );
}
