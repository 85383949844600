/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Modal, Card } from 'react-bootstrap';
import api from '../../../services/API';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import moment from 'moment';
import InputMask from 'react-input-mask';

export default function ModalEstoque(props) {
  const [alterando, setAlterando] = useState(false);
  const [buscando, setBuscando] = useState(false);
  const [saldo, setSaldo] = useState('');
  const [data, setData] = useState('');
  const [liberacoes, setLiberacoes] = useState([]);

  const alterarEstoque = async () => {
    setAlterando(true);
    const resultado = await api.post('/api/estoque/saldo', {
      id: props.id,
      codProduto: props.codProduto,
      rede: props.rede,
      saldo: saldo,
      qtdUltimoPedido: props.qtdUltimoPedido,
      qtdPenultimoPedido: props.qtdPenultimoPedido,
      qtdPontuada: props.qtdPontuada,
      data,
    });

    setTimeout(() => {
      if (resultado) {
        props.atualizarEstoque();
        toast.success(`Estoque atualizado com sucesso`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        props.onHide();
      }

      setSaldo('');
      setData('');
      setAlterando(false);
    }, 3000);
  };

  const buscarLiberacoes = async () => {
    setBuscando(true);
    const resultado = await api.get(
      `api/estoque/liberacoes?rede=${props.rede}&codProduto=${props.codProduto}`
    );
    setLiberacoes(resultado.data.liberacoes);
    setBuscando(false);
  };

  useEffect(() => {
    props.rede && buscarLiberacoes();
  }, [props]);

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Inserir Estoque</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-2">
        <form className="form mw-100">
          <div className="d-flex flex-column">
            <label htmlFor="saldo">Saldo</label>
            <input
              id="saldo"
              type="number"
              step="0.01"
              value={saldo}
              onChange={(e) => setSaldo(e.target.value)}
            />
          </div>
          <div className="d-flex flex-column">
            <label htmlFor="data">Data do Relatório</label>
            <InputMask
              id="data"
              name="data"
              type="text"
              placeholder="Data do Relatório"
              value={data}
              onChange={(e) => setData(e.target.value)}
              mask="99/99/9999"
            ></InputMask>
          </div>
        </form>
        <div className="mt-3">
          {buscando && (
            <Card className="mb-3 mt-3">
              <Card.Header>Buscando...</Card.Header>
              <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                <i className="fas fa-circle-notch fa-spin mt-2 mb-2"></i>
              </Card.Body>
            </Card>
          )}
          {!buscando &&
            liberacoes.map((saldo) => (
              <Card className="mb-3 mt-3">
                <Card.Header>
                  Editado por {saldo.nome} em{' '}
                  {moment(saldo.criadoEm).format('DD/MM/YYYY HH:mm:ss')}
                </Card.Header>
                <Card.Body>
                  <b>Saldo em estoque:</b> {saldo.parametros.saldo}
                  <br></br>
                  <b>Data do Relatório:</b>{' '}
                  {moment(saldo.parametros.data).format('DD/MM/YYYY')}
                </Card.Body>
              </Card>
            ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center align-items-center w-100">
          {alterando ? (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={alterarEstoque}
              disabled
            >
              SALVANDO...
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={alterarEstoque}
            >
              SALVAR
            </button>
          )}
          <button
            type="button"
            className="btn btn-danger m-1"
            onClick={props.onHide}
          >
            CANCELAR
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
