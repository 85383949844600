import React, { useState, useEffect } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import api from '../../../../services/API';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';
import axios from 'axios';

export default function ModalEditar(props) {
  const [editando, setEditando] = useState(false);
  const [data, setData] = useState({});
  const [codigo, setCodigo] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [razaoSocial, setRazaoSocial] = useState('');
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [endRua, setRua] = useState('');
  const [endNumero, setNumero] = useState('');
  const [endComplemento, setComplemento] = useState('');
  const [endBairro, setBairro] = useState('');
  const [endCidade, setCidade] = useState('');
  const [endEstado, setEstado] = useState('');
  const [endCep, setCep] = useState('');
  const [tipoPontuacao, setPontuacao] = useState('');
  const [responsavel, setResponsavel] = useState('');
  const [celular, setCelular] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [cenario, setCenario] = useState('');

  const validarTelefoneCelular = async (e) => {
    const valor = e.target.value.replace(/[^\d]/g, '');
    if (!valor) return;
    if (valor.length < 10) {
      toast.error(`${e.target.placeholder} inválido`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      e.target.placeholder === 'Telefone' ? setTelefone('') : setCelular('');
    }
  };

  const validarCampos = (data) => {
    const entries = Object.entries(data);
    let temNulo = false;
    entries.forEach((param) => {
      if (temNulo) return;
      if (param[1] === '' && param[0] === 'tipoPontuacao') {
        toast.error(`Tipo de pontuação obrigatório`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        temNulo = true;
        return;
      }
      if (param[1] === '' && param[0] === 'rede') {
        toast.error(`Rede obrigatório`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        temNulo = true;
        return;
      }
      if (
        param[1] === '' &&
        param[0] !== 'endComplemento' &&
        param[0] !== 'telefone' &&
        param[0] !== 'email'
      ) {
        const elemento = document.getElementsByName(param[0])[0];
        toast.error(`${elemento.placeholder} obrigatório`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        temNulo = true;
        return;
      }
    });

    return temNulo;
  };

  const atualizar = async (e) => {
    e.preventDefault();
    const dataUpdated = {
      codigo,
      cnpj,
      nomeFantasia,
      razaoSocial,
      endRua,
      endNumero,
      endComplemento,
      endBairro,
      endCidade,
      endEstado,
      endCep,
      responsavel,
      email,
      telefone,
      celular,
      tipoPontuacao,
      cenario: Number(cenario),
    };

    const temNulo = validarCampos(dataUpdated);
    if (temNulo) {
      return;
    }

    dataUpdated.endCep = dataUpdated.endCep.replace(/[^\d]/g, '');
    dataUpdated.cnpj = dataUpdated.cnpj.replace(/[^\d]/g, '');
    dataUpdated.telefone = dataUpdated.telefone.replace(/[^\d]/g, '');
    dataUpdated.celular = dataUpdated.celular.replace(/[^\d]/g, '');

    setEditando(true);
    const resultado = await api.put(`/api/revendas/${data.id}`, dataUpdated);

    if (resultado) {
      toast.success(`Revenda alterada com sucesso`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      props.onHide();
      props.atualizarDados();
    }
    setEditando(false);
  };

  useEffect(() => {
    if (props.data) {
      setData(props.data);
      setCodigo(props.data.codigo);
      setCnpj(props.data.cnpj);
      setNomeFantasia(props.data.nomeFantasia);
      setRazaoSocial(props.data.razaoSocial);
      setRua(props.data.endRua);
      setNumero(props.data.endNumero);
      setComplemento(props.data.endComplemento);
      setBairro(props.data.endBairro);
      setCidade(props.data.endCidade);
      setEstado(props.data.endEstado);
      setCep(props.data.endCep);
      setResponsavel(props.data.responsavel);
      setEmail(props.data.email);
      setTelefone(props.data.telefone);
      setCelular(props.data.celular);
      setPontuacao(props.data?.tipoPontuacao?.toString());
      setCenario(props.data.cenario);
    }
  }, [props]);

  const buscarCep = async (element) => {
    let input = element.target;
    let cep = input.value.replace(/[^\d]/g, '');
    if (!cep) return;
    const resultado = await axios.get(`https://viacep.com.br/ws/${cep}/json`);
    if (!resultado || resultado.data.erro) {
      toast.error('CEP inválido', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setCep('');
    } else {
      setRua(resultado.data.logradouro);
      setBairro(resultado.data.bairro);
      setCidade(resultado.data.localidade);
      setEstado(resultado.data.uf);
      setComplemento(resultado.data.complemento);
    }
  };

  const buscarCodigo = async (element) => {
    let input = element.target;
    let codigo = input.value;
    if (!codigo) return;
    const resultado = await api.get(`/api/revendas?codigo=${codigo}`);
    if (resultado.data.revendas.length > 0) {
      toast.error('Código em uso por outra Loja', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setCodigo('');
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Editar</Modal.Title>
      </Modal.Header>
      <Modal.Body className="mt-3 mb-3">
        <div className="d-flex flex-column align-items-center">
          <form className="form" onSubmit={atualizar}>
            <Row>
              <Col className="d-flex flex-column" xs={12} sm={4}>
                <label htmlFor="codigo">Código</label>
                <input
                  id="codigo"
                  name="codigo"
                  type="text"
                  placeholder="Código"
                  onBlur={buscarCodigo}
                  value={codigo}
                  onChange={(e) => setCodigo(e.target.value)}
                ></input>
              </Col>
              <Col className="d-flex flex-column" xs={12} sm={8}>
                <label htmlFor="cnpj">CNPJ</label>
                <InputMask
                  id="cnpj"
                  name="cnpj"
                  type="text"
                  placeholder="CNPJ"
                  mask="99.999.999/9999-99"
                  value={cnpj}
                  onChange={(e) => setCnpj(e.target.value)}
                ></InputMask>
              </Col>
            </Row>

            <label htmlFor="nomeFantasia">Nome Fantasia</label>
            <input
              id="nomeFantasia"
              name="nomeFantasia"
              type="text"
              placeholder="Nome Fantasia"
              value={nomeFantasia}
              onChange={(e) => setNomeFantasia(e.target.value)}
            ></input>
            <label htmlFor="razaoSocial">Razão Social</label>
            <input
              id="razaoSocial"
              name="razaoSocial"
              type="text"
              placeholder="Razão Social"
              value={razaoSocial}
              onChange={(e) => setRazaoSocial(e.target.value)}
            ></input>
            <Row>
              <Col className="d-flex flex-column" xs={12} sm={6}>
                <label htmlFor="responsavel">Responsável</label>
                <input
                  id="responsavel"
                  name="responsavel"
                  type="text"
                  placeholder="Responsável"
                  value={responsavel}
                  onChange={(e) => setResponsavel(e.target.value)}
                ></input>
              </Col>
              <Col className="d-flex flex-column" xs={12} sm={6}>
                <label htmlFor="endCep">CEP</label>
                <InputMask
                  id="endCep"
                  name="endCep"
                  type="text"
                  placeholder="CEP"
                  mask="99999-999"
                  onBlur={buscarCep}
                  value={endCep}
                  onChange={(e) => setCep(e.target.value)}
                ></InputMask>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex flex-column" xs={12} sm={9}>
                <label htmlFor="endRua">Endereço</label>
                <input
                  id="endRua"
                  name="endRua"
                  type="text"
                  placeholder="Endereço"
                  value={endRua}
                  onChange={(e) => setRua(e.target.value)}
                ></input>
              </Col>
              <Col className="d-flex flex-column" xs={12} sm={3}>
                <label htmlFor="endNumero">Número</label>
                <input
                  id="endNumero"
                  name="endNumero"
                  type="text"
                  placeholder="Número"
                  value={endNumero}
                  onChange={(e) => setNumero(e.target.value)}
                ></input>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex flex-column" xs={12} sm={6}>
                <label htmlFor="endBairro">Bairro</label>
                <input
                  id="endBairro"
                  name="endBairro"
                  type="text"
                  placeholder="Bairro"
                  value={endBairro}
                  onChange={(e) => setBairro(e.target.value)}
                ></input>
              </Col>
              <Col className="d-flex flex-column" xs={12} sm={6}>
                <label htmlFor="endComplemento">Complemento</label>
                <input
                  id="endComplemento"
                  name="endComplemento"
                  type="text"
                  placeholder="Complemento"
                  value={endComplemento}
                  onChange={(e) => setComplemento(e.target.value)}
                ></input>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex flex-column" xs={12} sm={6}>
                <label htmlFor="endCidade">Cidade</label>
                <input
                  id="endCidade"
                  name="endCidade"
                  type="text"
                  placeholder="Cidade"
                  value={endCidade}
                  onChange={(e) => setCidade(e.target.value)}
                ></input>
              </Col>
              <Col className="d-flex flex-column" xs={12} sm={6}>
                <label htmlFor="endEstado">Estado</label>
                <input
                  id="endEstado"
                  name="endEstado"
                  type="text"
                  placeholder="Estado"
                  value={endEstado}
                  onChange={(e) => setEstado(e.target.value)}
                  maxLength={2}
                ></input>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex flex-column" xs={12}>
                <label htmlFor="email">Email</label>
                <input
                  id="email"
                  name="email"
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></input>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex flex-column" xs={12} sm={6}>
                <label htmlFor="celular">Celular</label>
                <InputMask
                  id="celular"
                  name="celular"
                  type="text"
                  placeholder="Celular"
                  value={celular}
                  mask="(99) 99999-9999"
                  onChange={(e) => setCelular(e.target.value)}
                  onBlur={validarTelefoneCelular}
                ></InputMask>
              </Col>
              <Col className="d-flex flex-column" xs={12} sm={6}>
                <label htmlFor="telefone">Telefone</label>
                <InputMask
                  id="telefone"
                  name="telefone"
                  type="text"
                  placeholder="Telefone"
                  mask="(99) 9999-9999"
                  value={telefone}
                  onChange={(e) => setTelefone(e.target.value)}
                  onBlur={validarTelefoneCelular}
                ></InputMask>
              </Col>
              <Col className="d-flex flex-column" xs={12} sm={6}>
                <label htmlFor="cenario">Cenário</label>
                <select
                  id="cenario"
                  name="cenario"
                  value={cenario}
                  defaultValue={cenario}
                  onChange={(e) => setCenario(e.target.value)}
                  placeholder="Selecione"
                >
                  <option value={0}>Inicial</option>
                  <option value={1}>A</option>
                  <option value={2}>B</option>
                  <option value={3}>C</option>
                </select>
              </Col>
            </Row>

            <div className="d-flex flex-row justify-content-center align-items-center text-left">
              <label className="mb-0">Tipo de pontuação</label>
              <label className="m-0 p-3 f-16" htmlFor="tipoPontuacaoSistema">
                Sistema
              </label>
              <input
                className="m-0 p-3"
                id="tipoPontuacaoSistema"
                name="tipoPontuacao"
                type="radio"
                value={'0'}
                checked={tipoPontuacao === '0'}
                onChange={(e) => setPontuacao(e.target.value)}
              ></input>
              <label className="m-0 p-3 f-16" htmlFor="tipoPontuacaoSistema">
                Digitação
              </label>
              <input
                className="m-0 p-3"
                id="tipoPontuacaoFoto"
                name="tipoPontuacao"
                type="radio"
                value={'1'}
                checked={tipoPontuacao === '1'}
                onChange={(e) => setPontuacao(e.target.value)}
              ></input>{' '}
              <label className="m-0 p-3 f-16" htmlFor="tipoPontuacaoSistema">
                Foto
              </label>
              <input
                className="m-0 p-3"
                id="tipoPontuacaoFoto"
                name="tipoPontuacao"
                type="radio"
                value={'2'}
                checked={tipoPontuacao === '2'}
                onChange={(e) => setPontuacao(e.target.value)}
              ></input>
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center align-items-center w-100">
          {editando ? (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={atualizar}
              disabled
            >
              SALVANDO...
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={atualizar}
            >
              SALVAR
            </button>
          )}
          <button
            type="button"
            className="btn btn-danger m-1"
            onClick={props.onHide}
          >
            CANCELAR
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
