import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import api from '../../../../services/API';
import { toast } from 'react-toastify';

export default function ModalExcluir(props) {
  const [excluindo, setExcluir] = useState(false);
  const excluir = async () => {
    setExcluir(true);
    const resultado = await api.delete(`/api/pesquisa_mercado/${props.id}`);

    if (resultado) {
      toast.success(`Pesquisa excluída com sucesso`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      props.onHide();
      props.atualizarExclusao(props.id);
    }
    setExcluir(false);
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Apagar</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="d-flex justify-content-center align-items-center text-center w-100 p-4">
          <span>
            Tem certeza que deseja apagar a pesquida de mercado da Loja{' '}
            <b>{props.revenda}</b>?
          </span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center align-items-center w-100">
          {excluindo ? (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={excluir}
              disabled
            >
              APAGANDO...
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={excluir}
            >
              SIM
            </button>
          )}
          <button
            type="button"
            className="btn btn-danger m-1"
            onClick={props.onHide}
          >
            NÃO
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
