import React, { useEffect } from 'react';
import Quagga from 'quagga';
import ConfigurationsQuagga from "./ConfigurationsQuagga.json";

const QuaggaScanner = ({ sendCode, closeCamera, isMobile }) => {

  function startCamera() {
    let barcodeFound = false;
    Quagga.init(ConfigurationsQuagga, function (err) {
      if (err) {
        console.log(err);
        return
      }

    });
    Quagga.onDetected(({ codeResult }) => {
      if (!barcodeFound) {
        barcodeFound = true;
        sendCode(codeResult);
        Quagga.stop();
        closeCamera(false);
      }
    });
    return () => {
      Quagga.stop();
    }
  }

  useEffect(() => {
    startCamera()
  }, [])

  return <div
    id="interactive"
    className="viewport"
  />
}

export default QuaggaScanner;
