import React, { useState } from 'react';
import api from '../../../../services/API';
import { toast } from 'react-toastify';
import './styles.css';

export default function ToggleLiberarResgate(props) {
  const [liberarResgate, setLiberarResgate] = useState(props.liberado);

  const atualizar = async () => {
    setLiberarResgate(!liberarResgate)

    const dataUpdated = {
      resgateLiberado: !liberarResgate
    }

    const resultado = await api.put(`/api/revendas/${props.revenda}`, dataUpdated);

    if (resultado) {
      toast.success(`Liberação do resgate alterada com sucesso`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    }
  }

  return(
    <label className="switch">
      <input
        type="checkbox"
        onClick={()=> atualizar()}
        checked={liberarResgate}
      />
      <span className="slider round"></span>
    </label>
  )
};
