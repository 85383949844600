import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import api from '../../../services/API';
import { toast } from 'react-toastify';
import './styles.css';

export default function Perfil() {
  const parceiro = useSelector((state) => {
    return state.parceiro;
  });

  const [enviando, setEnviando] = useState(false);
  const [mensagem, setMensagem] = useState('');

  const enviar = async (e) => {
    e.preventDefault();
    if (!mensagem) {
      toast.error('Informe uma mensagem', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    }

    setEnviando(true);
    const resultado = await api.post('/api/enviar-email-contato', { mensagem });

    if (resultado) {
      setMensagem('');
      toast.success('Mensagem enviada com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    }
    setEnviando(false);
  };

  return (
    <Container className="mt-5 mb-5">
      <div>
        <h5>CONTATO</h5>
        <hr className="hr-loja mt-0" />
      </div>
      <div>
        <form
          onSubmit={enviar}
          className="form loja fundo-claro justify-content-center align-items-center"
        >
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="nome">Nome</label>
              <input
                id="nome"
                name="nome"
                type="text"
                placeholder="Nome"
                disabled
                value={parceiro.nome}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="nome">Email</label>
              <input
                id="email"
                name="email"
                type="text"
                placeholder="Email"
                disabled
                value={parceiro.email}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={4}>
              <label htmlFor="celular">Celular</label>
              <InputMask
                id="celular"
                name="celular"
                type="text"
                placeholder="Celular"
                value={parceiro.celular}
                mask="(99) 99999-9999"
                disabled
              ></InputMask>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={4}>
              <label htmlFor="telefone">Telefone</label>
              <InputMask
                id="telefone"
                name="telefone"
                type="text"
                placeholder="Telefone"
                value={parceiro.telefone}
                mask="(99) 9999-9999"
                disabled
              ></InputMask>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={4}>
              <label htmlFor="endCidade">Cidade</label>
              <input
                id="endCidade"
                name="endCidade"
                type="text"
                placeholder="Cidade"
                value={parceiro.endCidade}
                disabled
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12}>
              <label htmlFor="mensagem">Mensagem</label>
              <textarea
                type="text"
                id="mensagem"
                name="mensagem"
                className="contato-mensagem pt-3 pb-3"
                placeholder="Digite sua mensagem aqui"
                value={mensagem}
                onChange={(e) => setMensagem(e.target.value)}
              ></textarea>
            </Col>
          </Row>
          {enviando ? (
            <button disabled className="btn-enviar" type="submit">
              ENVIANDO...
            </button>
          ) : (
            <button className="btn-enviar" type="submit">
              ENVIAR
            </button>
          )}
        </form>
      </div>
    </Container>
  );
}
