import produce from 'immer';

const estadoInicial = {
  resgatando: false,
  atualizando: false,
  atualizandoSaldo: false,
  nome: null,
  cpf: null,
  email: null,
  celular: null,
  telefone: null,
  sexo: null,
  dataNascimento: null,
  endRua: null,
  endNumero: null,
  endBairro: null,
  endCidade: null,
  endEstado: null,
  endCep: null,
  endComplemento: null,
  saldoPontos: null,
  totalPontos: null,
  cnpjRevenda: null,
  tipoPontuacaoRevenda: null,
  aceitouRegulamento: null,
  cartao: null,
};

export default function parceiro(state = estadoInicial, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@autenticacaoParceiro/DESAUTENTICAR': {
        draft.resgatando = false;
        draft.atualizando = false;
        draft.nome = null;
        draft.cpf = null;
        draft.email = null;
        draft.celular = null;
        draft.telefone = null;
        draft.sexo = null;
        draft.dataNascimento = null;
        draft.endRua = null;
        draft.endNumero = null;
        draft.endBairro = null;
        draft.endCidade = null;
        draft.endEstado = null;
        draft.endCep = null;
        draft.endComplemento = null;
        draft.saldoPontos = null;
        draft.totalPontos = null;
        draft.cnpjRevenda = null;
        draft.tipoPontuacaoRevenda = null;
        draft.aceitouRegulamento = null;
        draft.cartao = null;
        break;
      }
      case '@autenticacaoParceiro/AUTENTICAR_SUCESSO': {
        draft.nome = action.payload.parceiro.nome;
        draft.cpf = action.payload.parceiro.cpf;
        draft.email = action.payload.parceiro.email;
        draft.celular = action.payload.parceiro.celular;
        draft.telefone = action.payload.parceiro.telefone;
        draft.sexo = action.payload.parceiro.sexo;
        draft.dataNascimento = action.payload.parceiro.dataNascimento;
        draft.endRua = action.payload.parceiro.endRua;
        draft.endNumero = action.payload.parceiro.endNumero;
        draft.endBairro = action.payload.parceiro.endBairro;
        draft.endCidade = action.payload.parceiro.endCidade;
        draft.endEstado = action.payload.parceiro.endEstado;
        draft.endCep = action.payload.parceiro.endCep;
        draft.endComplemento = action.payload.parceiro.endComplemento;
        draft.saldoPontos = action.payload.parceiro.saldoPontos;
        draft.totalPontos = action.payload.parceiro.totalPontos;
        draft.cnpjRevenda = action.payload.parceiro.revenda.cnpj;
        draft.tipoPontuacaoRevenda =
          action.payload.parceiro.revenda.tipoPontuacao;
        draft.aceitouRegulamento = action.payload.parceiro.aceitouRegulamento;
        draft.cartao = action.payload.parceiro.cartao;
        break;
      }
      case '@parceiro/ATUALIZAR_PERFIL': {
        draft.atualizando = true;
        break;
      }
      case '@parceiro/ATUALIZAR_PERFIL_FALHOU': {
        draft.atualizando = false;
        break;
      }
      case '@parceiro/ATUALIZAR_PERFIL_SUCESSO': {
        draft.nome = action.payload.nome;
        draft.email = action.payload.email;
        draft.celular = action.payload.celular;
        draft.telefone = action.payload.telefone;
        draft.sexo = action.payload.sexo;
        draft.dataNascimento = action.payload.dataNascimento;
        draft.endRua = action.payload.endRua;
        draft.endNumero = action.payload.endNumero;
        draft.endBairro = action.payload.endBairro;
        draft.endCidade = action.payload.endCidade;
        draft.endEstado = action.payload.endEstado;
        draft.endCep = action.payload.endCep;
        draft.endComplemento = action.payload.endComplemento;
        draft.atualizando = false;
        break;
      }
      case '@parceiro/RESGATAR_PREMIO': {
        draft.resgatando = true;
        break;
      }
      case '@parceiro/RESGATAR_PREMIO_FALHOU': {
        draft.resgatando = false;
        break;
      }
      case '@parceiro/RESGATAR_PREMIO_SUCESSO': {
        draft.resgatando = false;
        draft.saldoPontos = action.payload.saldoNovo;
        if (action.payload.cartao) {
          draft.cartao = action.payload.cartao;
        }
        break;
      }
      case '@parceiro/ACEITAR_REGULAMENTO': {
        draft.aceitouRegulamento = '1';
        break;
      }
      case '@parceiro/ALTERAR_STATUS_CARTAO': {
        draft.cartao.bloqueado = action.payload.status;
        draft.cartao.emDesbloqueio = action.payload.emDesbloqueio;
        if (
          action.payload.status === 1 &&
          action.payload.emDesbloqueio === false
        ) {
          draft.cartao = null;
        }
        break;
      }
      case '@parceiro/ATUALIZAR_SALDO': {
        draft.atualizandoSaldo = true;
        break;
      }
      case '@parceiro/ATUALIZAR_SALDO_FALHOU': {
        draft.atualizandoSaldo = false;
        break;
      }
      case '@parceiro/ATUALIZAR_SALDO_SUCESSO': {
        draft.saldoPontos = action.payload;
        break;
      }
      default:
    }
  });
}
