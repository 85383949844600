/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container } from 'react-bootstrap';
import api from '../../../services/API';
import ModalAlterarStatusResgate from '../ModalAlterarStatusResgate';
import ModalCancelarResgate from '../ModalCancelarResgate';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Row, Col } from 'react-bootstrap';
import sortFunc from '../../../utils/ordernarColunaInt';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import ModalMassRescueAlteration from './ModalMassRescueAlteration';
import ModalObsEdit from './ModalObsEdit';

const { SearchBar } = Search;

export default function Resgates() {
  const usuario = useSelector((state) => {
    return state.usuario;
  });
  const [redesSelect, setRedesSelect] = useState([]);
  const [redeSelecionada, setRedeSelecionada] = useState([]);
  const [
    mostrarModalAlterarStatusResgate,
    setMostrarModalAlterarStatusResgate,
  ] = useState(false);
  const [mostrarModalCancelarResgate, setMostrarModalCancelarResgate] =
    useState(false);
  const [resgate, setResgate] = useState('');
  const [data, setData] = useState([]);
  const [buscando, setBuscando] = useState(false);
  const [showSelectionIdsModal, setShowSelectionIdsModal] = useState(false);
  const [showObsEditModal, setShowObsEditModal] = useState(false);
  const [obs, setObs] = useState('');

  const formatarData = (celula, valor) => {
    return moment(valor.resgatadoEm).format('DD/MM/YYYY HH:mm:ss');
  };

  const formatarQtde = (celula, valor) => {
    return Number(valor.qtde).toLocaleString('pt-BR');
  };

  const formatarSaldo = (celula, valor) => {
    return Number(valor.saldoAnterior).toLocaleString('pt-BR');
  };

  const formatarGasto = (celula, valor) => {
    return Number(valor.pontosGastos).toLocaleString('pt-BR');
  };

  const formatarStatus = (celula, valor) => {
    if (valor.status === 0) {
      return (
        <span>
          <i className="fa fa-times"></i> Não
        </span>
      );
    } else if (valor.status === 1) {
      return (
        <span>
          <i className="fa fa-check"></i> Sim
        </span>
      );
    } else if (valor.status === 2) {
      return (
        <span>
          <i className="fa fa-truck"></i> Em trânsito
        </span>
      );
    } else if (valor.status === 3) {
      return (
        <span>
          <i className="fa fa-times"></i> Cancelado
        </span>
      );
    }
  };

  const formatarPremioResgatado = (celula, valor) => {
    return valor.obs ? `${valor.premio} - ${valor.obs}` : `${valor.premio}`;
  };

  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
    },
    {
      dataField: 'parceiro',
      text: 'Vendedor Loja',
      sort: true,
    },
    {
      dataField: 'revenda',
      text: 'Loja',
      sort: true,
    },
    {
      dataField: 'cidade',
      text: 'Cidade',
      sort: true,
    },
    {
      dataField: 'premio',
      text: 'Produto Resgatado',
      formatter: formatarPremioResgatado,
      sort: true,
    },
    {
      dataField: 'qtde',
      text: 'Quantidade',
      formatter: formatarQtde,
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'obs',
      text: 'Observação',
    },
    {
      dataField: 'saldoAnterior',
      text: 'Saldo no Ato',
      formatter: formatarSaldo,
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'pontosGastos',
      text: 'Pontos Utilizados',
      formatter: formatarGasto,
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'status',
      text: 'Entregue',
      formatter: formatarStatus,
      sort: true,
    },
    {
      dataField: 'resgatadoEm',
      text: 'Data do Resgate',
      formatter: formatarData,
      sort: true,
    },
    {
      text: 'Alterar Status',
      dataField: 'alterarStatus',
      isDummyField: true,
      hidden: [0].includes(usuario.permissao) ? true : false,
      formatter: (celula, valor) =>
        valor.status !== 3 ? (
          <button
            className="btn-limpo f-12 p-1"
            onClick={() => {
              visualizarModalAlterarStatusResgate(valor.id);
            }}
          >
            <i className="fas fa-edit"></i>
          </button>
        ) : (
          <button className="btn-limpo f-12 p-1" disabled>
            <i className="fas fa-edit"></i>
          </button>
        ),
    },
    {
      text: 'Alterar Observação',
      isDummyField: true,
      dataField: 'changeObs',
      formatter: (celula, valor) => (
        <button
          className="btn-limpo f-12 p-1"
          onClick={() => {
            setObs(valor.obs);
            setResgate(valor.id);
            setShowObsEditModal(true);
          }}
        >
          <i className="fas fa-edit"></i>
        </button>
      ),
      sort: true,
    },
    {
      text: 'Cancelar Resgate',
      isDummyField: true,
      dataField: 'cancelarResgate',
      hidden: [0].includes(usuario.permissao) ? true : false,
      formatter: (celula, valor) =>
        valor.status !== 3 ? (
          <button
            className="btn-limpo f-12 p-1"
            onClick={() => {
              visualizarModalCancelarResgate(valor.id);
            }}
          >
            <i className="fas fa-times f-red"></i>
          </button>
        ) : (
          <button className="btn-limpo f-12 p-1" disabled>
            CANCELADO
          </button>
        ),
    },
  ];

  const defaultSorted = [
    {
      dataField: 'resgatadoEm',
      order: 'desc',
    },
  ];

  useEffect(() => {
    buscarRedes();
    buscarResgates();
  }, []);

  const buscarRedes = async () => {
    const resultado = await api.get('/api/redes?ativo=1');
    if (resultado) {
      setRedesSelect(resultado.data.redes);
    }
  };

  useEffect(() => {
    if (buscando) {
      setData([]);
    }
  }, [buscando]);

  const buscarResgates = async () => {
    setBuscando(true);
    const resultado = await api.get(`/api/resgates?rede=${redeSelecionada}`);
    if (resultado) {
      setData(resultado.data.resgates);
    }
    setBuscando(false);
  };

  const visualizarModalAlterarStatusResgate = (id) => {
    setResgate(id);
    setMostrarModalAlterarStatusResgate(true);
  };

  const fecharModalAlterarStatusResgate = () => {
    setMostrarModalAlterarStatusResgate(false);
  };

  const visualizarModalCancelarResgate = (id) => {
    setResgate(id);
    setMostrarModalCancelarResgate(true);
  };

  const fecharModalCancelarResgate = () => {
    setMostrarModalCancelarResgate(false);
  };

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: data.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  const onHideSelectionIdsModal = (toUpdate) => {
    if (toUpdate) {
      setData((prev) =>
        prev.map((rescue) => {
          if (toUpdate.ids.includes(rescue.id)) {
            rescue.status = +toUpdate.status;
          }

          return rescue;
        })
      );
    }

    setShowSelectionIdsModal(false);
  };

  const onHideModalObsEdit = (toUpdate) => {
    if (toUpdate) {
      setData((prev) =>
        prev.map((rescue) => {
          if (toUpdate.id === rescue.id) {
            rescue.obs = toUpdate.obs;
          }
          return rescue;
        })
      );
    }
    setObs('');
    setShowObsEditModal(false);
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Resgates</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row>
        <Col xs={12} sm={6} md={4} lg={3} className="d-flex flex-column">
          <label htmlFor="redeSelecionada">Selecione uma Rede</label>
          <select
            className="input-select mb-3"
            name="redeSelecionada"
            id="redeSelecionada"
            onChange={(e) => setRedeSelecionada(e.target.value)}
            defaultValue=""
            placeholder=""
          >
            <option value="">Todas</option>
            {redesSelect.map((rede) => (
              <option value={rede.nome} key={rede.nome}>
                {rede.nome}
              </option>
            ))}
          </select>
        </Col>
        <Col
          xs={12}
          sm={1}
          className="d-flex flex-column justify-content-center align-items-center d-sm-block"
        >
          <button className="btn-busca" type="submit" onClick={buscarResgates}>
            {buscando ? (
              <i className="fas fa-circle-notch fa-spin"></i>
            ) : (
              <i className="fas fa-search"></i>
            )}
          </button>
        </Col>
      </Row>
      <div>
        <button
          onClick={() => setShowSelectionIdsModal(true)}
          className="btn-primario-slim px-4 d-flex g-4 align-items-center "
        >
          <i class="fas fa-stream mr-2"></i>
          <span>Alterar Status em Massa</span>
        </button>
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={data}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="d-flex flex-row-reverse">
                <SearchBar
                  {...props.searchProps}
                  placeholder="Buscar"
                  className="input-theme mr-2"
                />
              </div>
              <BootstrapTable
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                pagination={paginationFactory(options)}
                defaultSorted={defaultSorted}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
      <ModalCancelarResgate
        show={mostrarModalCancelarResgate}
        onHide={fecharModalCancelarResgate}
        resgate={resgate}
      />
      <ModalAlterarStatusResgate
        show={mostrarModalAlterarStatusResgate}
        onHide={fecharModalAlterarStatusResgate}
        resgate={resgate}
      />
      <ModalMassRescueAlteration
        open={showSelectionIdsModal}
        onHide={onHideSelectionIdsModal}
        resgate={resgate}
      ></ModalMassRescueAlteration>

      <ModalObsEdit
        open={showObsEditModal}
        onHide={onHideModalObsEdit}
        resgate={resgate}
        obs={obs}
        setObs={setObs}
      ></ModalObsEdit>
    </Container>
  );
}
