export const initialState = {
  enviando: false,
  codigo: '',
  cnpj: '',
  razaoSocial: '',
  nomeFantasia: '',
  endRua: '',
  endNumero: '',
  endComplemento: '',
  endBairro: '',
  endCidade: '',
  endEstado: '',
  endCep: '',
  tipoPontuacao: '',
  responsavel: '',
  celular: '',
  telefone: '',
  email: '',
  rede: '',
  redes: [],
};
