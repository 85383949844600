import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import api from '../../../services/API';
import sortFunc from '../../../utils/ordernarColunaInt';
import RemoteTableLoja from '../RemoteTableLoja';

const columns = [
  {
    dataField: 'codigo',
    text: 'Código',
  },
  {
    dataField: 'nome',
    text: 'Produto',
  },
  {
    dataField: 'valorPontos',
    text: 'Pontos',
    formatter: (celula, valor) => {
      return Number(valor.valorPontos).toLocaleString('pt-BR');
    },
    sort: true,
    sortFunc: sortFunc,
  },
];

export default function ListaPontos() {
  const cnpjRevenda = useSelector((state) => state.parceiro.cnpjRevenda);

  // useEffect(() => {
  //   buscarProdutos();
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // const buscarProdutos = async () => {
  //   const resultado = await api.get(
  //     `/api/produtos_revenda?cnpjRevenda=${cnpjRevenda}`
  //   );
  //   if (resultado) {
  //     setData(resultado.data.produtosRevenda);
  //   }
  // };

  return (
    <Container className="mt-5 mb-5">
      {/* <div>
        <h5 className='d-inline'>PONTOS POR PRODUTO</h5>
        <hr className="hr-loja mt-0" />
      </div> */}
      <div>
        <RemoteTableLoja
          dataId="id"
          url={`/api/produtos_revenda?cnpjRevenda=${cnpjRevenda}`}
          colunas={columns}
          filtros={{ cnpjRevenda: cnpjRevenda }}
          ordenacaoInicial={{ campo: 'valorPontos', direcao: 'desc' }}
        />
      </div>
    </Container>
  );
}
