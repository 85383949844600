import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { store } from '../../store/';

export default function RotaPainel({
  component: Component,
  rotaPrivada = false,
  ...atributos
}) {
  const { autenticadoPainel } = store.getState().autenticacao;

  if (!autenticadoPainel && rotaPrivada) {
    return <Redirect to="/painel/" />;
  }

  // if (autenticadoPainel && !rotaPrivada) {
  //   return <Redirect to="/painel/dashboard" />;
  // }

  return <Route {...atributos} component={Component} />;
}
