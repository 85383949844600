import React, { useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import './styles.css';
import { Form, Input } from '@rocketseat/unform';
import { useDispatch, useSelector } from 'react-redux';
import { autenticarParceiro } from '../../../store/modules/autenticacao/actions';
import Footer from '../../../components/Loja/Footer';
import Header from '../../../components/Loja/Header';
import { Link } from 'react-router-dom';

export default function Login() {
  const dispatch = useDispatch();
  const enviando = useSelector((state) => state.autenticacao.autenticandoLoja);

  useEffect(() => {
    document.title = 'Ruby Rose - Login';
  }, []);

  const solicitarAutenticacao = ({ cpf, senha }) => {
    dispatch(autenticarParceiro(cpf, senha));
  };

  return (
    <div style={{ backgroundColor: '#fff', overflowX: 'hidden' }}>
      {/* <Header auto /> */}
      <Container className="d-flex flex-column justify-content-center">
        <img
          style={{ alignSelf: 'center', width: '100vw' }}
          src="imagens/ruby-rose-participe.jpg"
          alt="Ruby Rose"
        />
        <Row className="justify-content-center mt-5 mb-5">
          <Form
            onSubmit={solicitarAutenticacao}
            className="form-login form-login-loja"
          >
            <h5>FAÇA SEU LOGIN</h5>
            <label>Entre com seus dados e acesse sua conta</label>
            <label htmlFor="cpf">CPF</label>
            <Input name="cpf" type="text" placeholder="CPF"></Input>
            <label htmlFor="senha">Senha</label>
            <Input name="senha" type="password" placeholder="Senha"></Input>

            <button disabled={enviando} className="btn-primario" type="submit">
              {enviando ? 'VALIDANDO...' : 'ENTRAR'}
            </button>
            <Link
              className="mb-3 mt-3 text-center link-clean"
              to="/esqueceu-senha"
            >
              Esqueceu sua senha?
            </Link>
          </Form>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}
