import React, {useEffect,useState} from 'react';
import Quagga from 'quagga';
import './style.css';
import api from '../../../../../services/API';
import { toast } from 'react-toastify';

const Scanner = (props) => {
  const [isOnCamera, setIsOnCamera] = useState(false);
  const [barcode, setBarCode] = useState('');

  function startCamera() {
    Quagga.init({
      inputStream : {
        type : "LiveStream",
        constraints: {
          width: 248,
          height: 248,
          facingMode: "environment",
        },
      },
      locator: {
        patchSize: "medium",
        halfSample: true
      },
      decoder : {
        readers : ["ean_reader"]
      },
      locate: false
    }, function(err) {
        if (err) {
            console.log(err);
            return
        }
        if(isOnCamera) {
          Quagga.stop();
        } else {
          Quagga.start();

        }
    });
    Quagga.onDetected(onDetected);
  }

  const onDetected = ({codeResult}) => {
    verifyCode(codeResult);
  }

  useEffect(() => {
    startCamera();
  }, [isOnCamera])


  const verifyCode = async (codeResult) => {
    try {
      const { data } = await api.get(`api/produtos/barcode/${codeResult.code}`);
      if( data.codigo === "" ) {
        return;
      }
      props.handleInsertCode(data.codigo);
    }
    catch(error) {
      console.log(error);
    }
  }

  return <div style={{
      display: 'flex',
      flexDirection:'column',
      padding: '16px',
      alignItems: 'center',
      justifyContent: 'center',
      rowGap: '32px',
      maxWidth: '64%',
    }}>
    <div style={{display: 'flex', columnGap: '16px'}} >
     <button className="btn-enviar" style={{ height: 'auto' }} onClick={() => setIsOnCamera(!isOnCamera)}>
        {!isOnCamera ? 'Desligar Câmera' : 'Ligar Câmera'}
      </button>
    </div>

    <div
      id="interactive"
      className="viewport"
    />
  </div>
  ;
}

export default Scanner;
