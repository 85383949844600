import { useReducer } from 'react';

export const useForm = ({ initialState }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case 'updateField':
        return { ...state, [action.field]: action.value };
      case 'resetAll':
        return initialState;
      default:
        throw new Error(`Ação desconhecida: ${action.type}`);
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const setState = (field, value) => {
    dispatch({ type: 'updateField', field, value });
  };

  const resetAllState = () => {
    dispatch({ type: 'resetAll' });
  };

  return { state, setState, dispatch, resetAllState };
};
