import React from 'react';
import './style.css';
import api from '../../../../../services/API';
import { toast } from 'react-toastify';
import QuaggaScanner from '../Quagga';
import ZxingScanner from '../Zxing';
import { useSelector } from 'react-redux';

const Scanner = ({
  handleInsertCode,
  isMobile,
  handleCloseScanner,
  isUseQuagga,
}) => {
  const cnpjRevenda = useSelector((state) => state.parceiro.cnpjRevenda);

  const verifyCode = async (codeResult) => {
    try {
      const response = await api.get(`api/produtos/barcode/${codeResult}`);

      if (response.data.codigo === '' || response.data === 'undefined') return;

      const { data } = response;

      const responseCodeRevenda = await api.get(
        `/api/produtos_revenda?cnpjRevenda=${cnpjRevenda}&&pagina=1&ordenacao={"campo":"valorPontos","direcao":"desc"}&buscar=${
          data.codigo
        }&limite=10&filtros=${JSON.stringify({ cnpjRevenda: cnpjRevenda })}`
      );

      toast.success('Produto selecionado com sucesso!', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });

      handleInsertCode(responseCodeRevenda.data.rows[0].codigo);
    } catch (error) {
      handleInsertCode('');
      console.log(error);
    }
  };

  return (
    <div className="contentcamera">
      {isUseQuagga ? (
        <QuaggaScanner sendCode={verifyCode} closeCamera={handleCloseScanner} />
      ) : (
        <ZxingScanner sendCode={verifyCode} closeCamera={handleCloseScanner} />
      )}
    </div>
  );
};

export default Scanner;
