import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import api from '../../../services/API';
import { useParams } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Toggle from 'react-bootstrap-toggle';
import { toast } from 'react-toastify';
import ModalExcluir from './ModalExcluir/index';
import ModalEditar from './ModalEditar/index';
import { useSelector } from 'react-redux';
import RemoteTable from '../RemoteTable';

export default function DetalheRevenda() {
  const usuario = useSelector((state) => {
    return state.usuario;
  });

  const { cnpj } = useParams();
  const [revenda, setRevenda] = useState([]);
  const [buscando, setBuscando] = useState(false);

  useEffect(() => {
    setBuscando(true);
    buscarRevenda();
    setBuscando(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const buscarRevenda = async () => {
    const resultado = await api.get(`/api/revendas?cnpj=${cnpj}`);
    if (resultado) {
      const revenda = resultado.data.revendas[0];

      revenda.tipoPontuacao = revenda.tipoPontuacao ? 'Foto' : 'Sistema';

      let cnpj = revenda.cnpj.replace(/[^\d]/g, '');
      revenda.cnpj = cnpj.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5'
      );

      revenda.endereco = revenda.endComplemento
        ? `${revenda.endRua}, ${revenda.endNumero}<br/>${revenda.endBairro} - ${revenda.endComplemento}<br/>${revenda.endCidade} - ${revenda.endEstado}`
        : `${revenda.endRua}, ${revenda.endNumero}<br/>${revenda.endBairro}<br/>${revenda.endCidade} - ${revenda.endEstado}`;

      revenda.telefone = revenda.telefone
        ? `(${revenda.telefone.substring(0, 2)}) ${revenda.telefone.substring(
            2,
            6
          )}-${revenda.telefone.substring(6)}`
        : '';

      revenda.celular = revenda.telefone
        ? `(${revenda.celular.substring(0, 2)}) ${revenda.celular.substring(
            2,
            7
          )}-${revenda.celular.substring(7)}`
        : '';

      revenda.contato = '';
      if (revenda.telefone && revenda.celular) {
        revenda.contato = `${revenda.celular} / ${revenda.telefone}`;
      } else if (revenda.celular) {
        revenda.contato = `${revenda.celular}`;
      } else {
        revenda.contato = '';
      }

      setRevenda(revenda);
    }
  };

  const columns = [
    {
      text: 'Código Loja',
      dataField: 'codigo',
    },
    {
      text: 'Código Ruby Rose',
      dataField: 'codigoPadrao',
    },
    {
      text: 'Descrição do Produto',
      dataField: 'nome',
    },

    {
      text: 'Pontos Unitário',
      dataField: 'valorPontos',
      formatter: (celula, valor) => {
        return Number(valor.valorPontos).toLocaleString('pt-BR');
      },
      sort: true,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === 'asc') {
          return a - b;
        }
        return b - a;
      },
    },
    {
      isDummyField: true,
      dataField: 'ativo',
      text: 'Ativo',
      sort: true,
      hidden: [2].includes(usuario.permissao) ? false : true,
    },
    {
      dataField: 'editar',
      isDummyField: true,
      text: 'Editar',
      sort: false,
      hidden: [1, 2].includes(usuario.permissao) ? false : true,
    },
    {
      dataField: 'apagar',
      isDummyField: true,
      text: 'Apagar',
      sort: false,
      hidden: [2].includes(usuario.permissao) ? false : true,
    },
  ];

  return (
    <Container fluid>
      {buscando ? (
        <h4 className="pt-3 pb-3 text-muted">Carregando...</h4>
      ) : (
        <h4 className="pt-3 pb-3 f-black">{revenda.nomeFantasia}</h4>
      )}
      <hr className="hr-loja mt-0 mb-4" />
      <Row>
        <Col md={6} lg={3} className="mb-2">
          <div className="card-detalhe p-3">
            <span>
              <b>Razão Social</b>
            </span>
            {buscando ? (
              <span className="text-muted">Carregando...</span>
            ) : (
              <span>{revenda.razaoSocial}</span>
            )}
            <br />
            <span>
              <b>Tipo de Pontuação</b>
            </span>
            {buscando ? (
              <span className="text-muted">Carregando...</span>
            ) : (
              <span>{revenda.tipoPontuacao}</span>
            )}
          </div>
        </Col>
        <Col md={6} lg={3} className="mb-2">
          <div className="card-detalhe p-3">
            <span>
              <b>CNPJ</b>
            </span>
            {buscando ? (
              <span className="text-muted">Carregando...</span>
            ) : (
              <span>{revenda.cnpj}</span>
            )}
            <br />
            <span>
              <b>Email</b>
            </span>
            {buscando ? (
              <span className="text-muted">Carregando...</span>
            ) : (
              <span>{revenda.email}</span>
            )}
          </div>
        </Col>
        <Col md={6} lg={3} className="mb-2">
          <div className="card-detalhe p-3">
            <span>
              <b>Responsável</b>
            </span>
            {buscando ? (
              <span className="text-muted">Carregando...</span>
            ) : (
              <span>{revenda.responsavel}</span>
            )}
            <br />
            <span>
              <b>Contato</b>
            </span>
            {buscando ? (
              <span className="text-muted">Carregando...</span>
            ) : (
              <span>{revenda.contato}</span>
            )}
          </div>
        </Col>
        <Col md={6} lg={3} className="mb-2">
          <div className="card-detalhe p-3">
            <span>
              <b>Endereço</b>
            </span>
            {buscando ? (
              <span className="text-muted">Carregando...</span>
            ) : (
              <span
                dangerouslySetInnerHTML={{ __html: revenda.endereco }}
              ></span>
            )}
            <br />
            <span>
              <b>CEP</b>
            </span>
            {buscando ? (
              <span className="text-muted">Carregando...</span>
            ) : (
              <span>{revenda.endCep}</span>
            )}
          </div>
        </Col>
      </Row>
      <h4 className="pt-3 pb-3 f-black">Produtos</h4>
      <hr className="hr-loja mt-0 mb-4" />

      <RemoteTable
        dataId="id"
        url={`/api/produtos_revenda`}
        colunas={columns}
        filtros={{ cnpjRevenda: cnpj }}
        ordenacaoInicial={{ campo: 'codigo', direcao: 'asc' }}
        fieldModalExlcluir="codigo"
        modalEdit={ModalEditar}
        mdal
      />
    </Container>
  );
}
