import './styles.css';

import { Route, Switch } from 'react-router-dom';

import CadastrarVendedor from '../../../components/Loja/CadastrarVendedor/index';
import Cartao from '../../../components/Loja/Cartao/index';
import Contato from '../../../components/Loja/Contato';
import DetalhePremio from '../../../components/Loja/DetalhePremio';
import Footer from '../../../components/Loja/Footer';
import Header from '../../../components/Loja/Header';
import ListaPontos from '../../../components/Loja/ListaPontos';
import Midias from '../../../components/Loja/Midias/index';
import ModalRegulamento from '../../../components/Loja/ModalRegulamento';
import Perfil from '../../../components/Loja/Perfil';
import Pontuacoes from '../../../components/Loja/Pontuacoes';
import PremiosLoja from '../../../components/Loja/PremiosLoja';
import Privacidade from '../Privacidade';
import React from 'react';
import Regulamento from '../../../components/Loja/Regulamento';
import Resgates from '../../../components/Loja/Resgates';
import Slider from '../../../components/Loja/Slider';
import UploadFoto from '../../../components/Loja/UploadFoto';
import UploadFotoTesteBarcode from '../../../components/Loja/UploadFotoTesteBarcode';

export default function Home() {
  return (
    <>
      <Header auto />
      <main>
        <Switch>
          <Route
            exact
            path="/loja"
            component={(props) => {
              return (
                <div className="mt-5">
                  <Slider />
                  <PremiosLoja />
                </div>
              );
            }}
          />
          <Route
            exact
            path="/loja/credito"
            component={(props) => <PremiosLoja tipo="credito" />}
          />
          <Route
            exact
            path="/premio/:codigo"
            component={(props) => <DetalhePremio />}
          />
          <Route exact path="/perfil" component={(props) => <Perfil />} />
          <Route
            exact
            path="/pontuacoes"
            component={(props) => <Pontuacoes />}
          />
          <Route exact path="/resgates" component={(props) => <Resgates />} />
          {/* <Route
            exact
            path="/enviar-pontuacao"
            component={(props) => <UploadFoto />}
          /> */}
           <Route
            exact
            path="/enviar-pontuacao"
            component={(props) => <UploadFotoTesteBarcode />}
          />
          <Route
            exact
            path="/pontos-produtos"
            component={(props) => <ListaPontos />}
          />
          <Route
            exact
            path="/regulamento"
            component={(props) => <Regulamento />}
          />
          <Route
            exact
            path="/politica-privacidade"
            component={(props) => <Privacidade />}
          />
          <Route
            path="/loja/cadastrar-vendedor/:idRevenda"
            component={(props) => <CadastrarVendedor {...props} />}
          />
          <Route exact path="/cartao" component={(props) => <Cartao />} />
          <Route exact path="/contato" component={(props) => <Contato />} />
          <Route exact path="/fotos-videos" component={(props) => <Midias />} />
        </Switch>
      </main>
      <Footer />
      <ModalRegulamento />
    </>
  );
}
