import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { store } from '../../store';

export default function RotaLoja({
  component: Component,
  rotaPrivada = false,
  ...atributos
}) {
  const { autenticadoLoja } = store.getState().autenticacao;

  if (!autenticadoLoja && rotaPrivada) {
    return <Redirect to="/" />;
  }

  // if (autenticadoLoja && !rotaPrivada) {
  //   return <Redirect to="/loja" />;
  // }

  return <Route {...atributos} component={Component} />;
}
